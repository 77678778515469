import {Button, Grid, Typography} from "@mui/material"
import CampaignLinkDecorator from "./CampaignLinkDecorator"
import { Box, Container } from "@mui/system"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import platform from "platform-detect"
import React, { useMemo } from "react"
import img_blob from "../assets/images/blob.svg"
import theme from "../theme"
import i18next from "./i18n"
import withTrans from "./withTrans"
import Link from "gatsby-link";

const AdDownloadApp = props => {
  const { t } = props

  const images = useStaticQuery(graphql`
    query {
      iphone: allFile(
        filter: {
          relativePath: { regex: "ads/landingpage/screenshot-iphone-.*.png/" }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(width: 560, placeholder: BLURRED, quality: 90)
            }
          }
        }
      }
      google: allFile(
        filter: {
          relativePath: { regex: "/ads/landingpage/screenshot-google-.*.png/" }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(width: 560, placeholder: BLURRED, quality: 90)
            }
          }
        }
      }
    }
  `)

  const matchIphone = useMemo(
    () =>
      images.iphone.edges.find(
        ({ node }) =>
          `ads/landingpage/screenshot-iphone-${i18next.language}.png` ===
          node.relativePath
      ),
    [images]
  )

  const matchGoogle = useMemo(
    () =>
      images.google.edges.find(
        ({ node }) =>
          `ads/landingpage/screenshot-google-${i18next.language}.png` ===
          node.relativePath
      ),
    [images]
  )

  const imgIphone = getImage(matchIphone.node.childImageSharp)
  const imgGoogle = getImage(matchGoogle.node.childImageSharp)

  let screenshot = platform.ios ? imgIphone : imgGoogle
  return (
    <Container disableGutters maxWidth="lg">
      <Box {...props} component="aside">
        <Grid
          container
          sx={{
            textAlign: { xs: "center" },
            overflow: "hidden",
          }}
          borderRadius={"16px"}
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item xs={12} md={5}>
            <Typography
              variant="h2"
              component="div"
              sx={{
                fontSize: { md: "3rem" },
                lineHeight: { md: "3.75rem" },
                textAlign: { md: "left" },
              }}
            >
              {t("landing_page.calorie_counter.cta_2")}
            </Typography>
              <Button // todo left-align this button somehow
                  color="secondary"
                  variant="contained"
                  disableElevation
                  component={Link}
                  to={CampaignLinkDecorator({ language: i18next.language, utm_campaign: 'download_app' })}
                  sx={{
                      width: { md: 343 },
                      backgroundColor: "#099250",
                      color: theme.palette.text.light,
                  }}
              >
                  {t("banner.large.button_text")}
              </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${img_blob})`,
              backgroundPosition: "center bottom",
              backgroundSize: "contain",
              mt: { xs: theme.spacing(4) },
              height: { xs: 380 },
            }}
          >
            <Box
              component={GatsbyImage}
              image={screenshot}
              sx={{
                width: { xs: 280 },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default withTrans(AdDownloadApp)
