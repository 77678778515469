import { Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import theme from "../theme"
import withTrans from "./withTrans"

const StoreStats = props => {
  const { title, t } = props
  return (
    <Box {...props}>
      {title && (
        <Typography
          variant="subtitle1"
          component="div"
          textAlign="center"
          sx={{
            textAlign: "center",
            display: { xs: "none", md: "block" },
          }}
          mb={8}
        >
          {title}
        </Typography>
      )}
      <Grid
        container
        direction="row"
        rowSpacing={4}
        justifyContent="space-evenly"
        alignItems="center"
        sx={{
          backgroundColor: "secondary.light",
          borderRadius: 3,
          textAlign: "center",
          px: { xs: theme.spacing(5) },
          pb: { xs: theme.spacing(5) },
        }}
      >
        {title && (
          <Grid
            item
            xs={12}
            sx={{
              display: { xs: "inline-block", md: "none" },
              marginBottom: theme.spacing(1),
            }}
          >
            <Typography
              variant="subtitle1"
              component="span"
              sx={{ color: "#084C2E" }}
            >
              {title}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <Typography
            variant="subtitle2"
            component="div"
            color="secondary.dark"
            sx={{
              fontSize: { xs: "3rem" },
              display: { md: "inline-block" },
            }}
          >
            {t("landing_page.calorie_counter.key_figures.reviews.heading")}
          </Typography>
          <br />
          <Typography variant="body1" component="span" color="primary.dark">
            {t("landing_page.calorie_counter.key_figures.reviews.text")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            variant="subtitle2"
            component="div"
            color="secondary.dark"
            sx={{
              fontSize: { xs: "3rem" },
              display: { md: "inline-block" },
            }}
          >
            {t("landing_page.calorie_counter.key_figures.rating.heading")}
          </Typography>
          <br />
          <Typography variant="body1" component="span" color="primary.dark">
            {t("landing_page.calorie_counter.key_figures.rating.text")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            variant="subtitle2"
            component="div"
            color="secondary.dark"
            sx={{
              fontSize: { xs: "3rem" },
              display: { md: "inline-block" },
            }}
          >
            {t("landing_page.calorie_counter.key_figures.downloads.heading")}
          </Typography>
          <br />
          <Typography variant="body1" component="span" color="primary.dark">
            {t("landing_page.calorie_counter.key_figures.downloads.text")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withTrans(StoreStats)
