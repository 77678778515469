import { Box, Grid, Typography } from "@mui/material"
import React from "react"
import img_business_insider from "../assets/images/business_insider.svg"
import img_cosmopolitan from "../assets/images/cosmopolitan.svg"
import img_gq from "../assets/images/gq.svg"
import img_healthline from "../assets/images/healthline.svg"
import img_paste from "../assets/images/paste.svg"
import img_vanity_fair from "../assets/images/vanity_fair.svg"
import img_vogue from "../assets/images/vogue.svg"
import theme from "../theme"
import withTrans from "./withTrans"

const PressMentions = props => {
  const { t } = props
  return (
    <Box
      {...props}
      container
      textAlign="center"
      sx={{
        backgroundColor: "secondary.dark",
        p: theme.spacing(6),
      }}
    >
      <Typography
        variant="body2"
        color="secondary.light"
        sx={{
          pb: theme.spacing(4),
          textAlign: "center",
          display: "inline-block",
        }}
        gutterBottom
      >
        {t("landing_page.calorie_counter.media_coverage.heading")}
      </Typography>
      <Grid
        container
        direction="row"
        rowSpacing={2}
        columnSpacing={4}
        justifyContent={{ md: "center" }}
      >
        <Grid item xs={12} md="auto">
          <img src={img_cosmopolitan} alt="Cosmopolitan" />
        </Grid>
        <Grid item xs={12} md="auto">
          <img src={img_business_insider} alt="Business Insider" />
        </Grid>
        <Grid item xs={12} md="auto">
          <img src={img_healthline} alt="Healthline" />
        </Grid>
        <Grid item xs={12} md="auto">
          <img src={img_vogue} alt="Vogue" />
        </Grid>
        <Grid item xs={12} md="auto">
          <img src={img_gq} alt="GQ" />
        </Grid>
        <Grid item xs={12} md="auto">
          <img src={img_vanity_fair} alt="Vanity Fair" />
        </Grid>
        <Grid item xs={12} md="auto">
          <img src={img_paste} alt="Paste" />
        </Grid>
      </Grid>
    </Box>
  )
}

export default withTrans(PressMentions)
