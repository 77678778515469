import { Container } from "@mui/system"
import { Button } from "@mui/material"
import CampaignLinkDecorator from "../components/CampaignLinkDecorator"
import i18next from "i18next"
import * as React from "react"
import AdDownloadApp from "../components/AdDownloadApp"
import Layout from "../components/Layout"
import PageHeading from "../components/PageHeading"
import PressMentions from "../components/Press"
import Screenshots from "../components/Screenshots"
import Seo from "../components/Seo"
import StoreStats from "../components/StoreStats"
import Textblock from "../components/Textblock"
import withTrans from "../components/withTrans"
import theme from "../theme"
import Link from "gatsby-link";

const Landing = ({ t }) => {
  return (
    <Layout pageName="calorie-counter" theme={theme}>
      <Container>
          <PageHeading title={t("landing_page.calorie_counter.heading")}>
              {t("landing_page.calorie_counter.cta_1")}

              <Button
                  color="secondary"
                  variant="contained"
                  component={Link}
                  disableElevation
                  to={CampaignLinkDecorator({ language: i18next.language, utm_campaign: 'calorie_counter' })}
                  sx={{
                      width: { md: 343 },
                      backgroundColor: "#099250",
                      color: theme.palette.text.light,
                      marginTop: "16px"
                  }}
              >
                  {t("banner.large.button_text")}
              </Button>
          </PageHeading>

      </Container>

      <Screenshots />

      <Container>
        <Textblock>
          {t("landing_page.calorie_counter.content_1.text", "")}
        </Textblock>

        <StoreStats
          mt={8}
          title={t("landing_page.calorie_counter.key_figures.heading")}
        />

        <Textblock
          mt={6}
          title={t("landing_page.calorie_counter.content_2.heading", "")}
        >
          {t("landing_page.calorie_counter.content_2.texts", "")}
        </Textblock>
      </Container>

      <AdDownloadApp mt={4} />

      <PressMentions mt={4} />
    </Layout>
  )
}

export default withTrans(Landing)

export const Head = () => (
  <Seo title={i18next.t("landing_page.calorie_counter.title")} />
)
