import { Box, Typography } from "@mui/material"
import React from "react"
import theme from "../theme"

const Textblock = props => {
  const { title, children } = props
  return (
    <Box
      sx={{
        mx: { md: theme.spacing(24) },
      }}
      {...props}
      textAlign={{ xs: "left" }}
    >
      {title && (
        <Typography
          variant="h2"
          component="div"
          sx={{ fontSize: { md: "1.875rem" }, lineHeight: { md: "2.375rem" } }}
        >
          {title}
        </Typography>
      )}
      {children && (
        <Typography variant="body1" component="p">
          {children}
        </Typography>
      )}
    </Box>
  )
}

export default Textblock
