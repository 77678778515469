import { Grid } from "@mui/material"
import { Box } from "@mui/system"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import i18next from "i18next"
import platform from "platform-detect"
import React, { useMemo } from "react"
import img_blob from "../assets/images/blob.svg"
import theme from "../theme"

const Screenshots = () => {
  const images = useStaticQuery(graphql`
    query {
      iphone: allFile(
        filter: {
          relativePath: { regex: "ads/landingpage/screenshot-iphone-.*.png/" }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(width: 640, placeholder: BLURRED, quality: 90)
            }
          }
        }
      }
      google: allFile(
        filter: {
          relativePath: { regex: "/ads/landingpage/screenshot-google-.*.png/" }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(width: 640, placeholder: BLURRED, quality: 90)
            }
          }
        }
      }
    }
  `)

  const matchIphone = useMemo(
    () =>
      images.iphone.edges.find(
        ({ node }) =>
          `ads/landingpage/screenshot-iphone-${i18next.language}.png` ===
          node.relativePath
      ),
    [images]
  )

  const matchGoogle = useMemo(
    () =>
      images.google.edges.find(
        ({ node }) =>
          `ads/landingpage/screenshot-google-${i18next.language}.png` ===
          node.relativePath
      ),
    [images]
  )

  const imgIphone = getImage(matchIphone.node.childImageSharp)
  const imgGoogle = getImage(matchGoogle.node.childImageSharp)

  let firstScreen = platform.ios ? imgIphone : imgGoogle
  let secondScreen = firstScreen === imgIphone ? imgGoogle : imgIphone
  return (
    <Grid
      container
      alignItems="flex-end"
      justifyContent="center"
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${img_blob})`,
        backgroundPosition: { xs: "center top", sm: "center bottom" },
        backgroundSize: { xs: "cover", sm: "contain" },
        overflow: "hidden",
        textAlign: "center",
        height: { xs: 320, sm: 320, md: 512 },
        mt: { sm: theme.spacing(4), md: theme.spacing(6) },
      }}
    >
      <Grid item alignItems="baseline">
        <Box
          component={GatsbyImage}
          sx={{
            verticalAlign: "bottom",
            width: { xs: 160, sm: 240, md: 320 },
            transform: "translateX(20px)",
          }}
          image={firstScreen}
        />
      </Grid>
      <Grid
        item
        sx={{
          display: "inline-flex",
          transform: "translateX(-20px)",
        }}
        alignItems="baseline"
      >
        <Box
          component={GatsbyImage}
          sx={{
            verticalAlign: "bottom",
            width: { xs: 160, sm: 240, md: 320 },
          }}
          image={secondScreen}
        />
      </Grid>
    </Grid>
  )
}

export default Screenshots
